.Selector {
    position: relative;
}

.Title {
    display: inline;
}

.Title:hover {
    color: var(--color5);
}

.FloatMenu {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 120%;
    right: 0;
    width: 150px;
    background-color: var(--color2);
    border: 1px solid var(--color1);
    padding-left: 5px;
}

.FloatMenu Button {
    width: 100%;
    flex-direction: row;
    text-align: left;
}

.FloatMenu > * > * {
    display: inline;
    margin: 0;
}

button {
    padding: 2px 0 0 0;
}
.Photo {
    width: 200px;
    border-radius: 100px;
    cursor: default;
    user-select: none;
    -webkit-user-drag: none;
}

.TextColumn h1,
.TextColumn p {
    text-align: center;
    margin-left: 0;
}

.extraMargin {
    margin-left: 5px;
}

@media all and (min-width: 800px) {
    .TextColumn h1,
    .TextColumn p {
        text-align: left;
        margin-left: 20px;
    }
}
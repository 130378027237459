.Row {
    display: flex;
    align-items: center;
}

.Row:hover {
    color: var(--color5);
    cursor: pointer;
}

.Row p{
    margin:0 0 0 10px;
}
.Content > * {
    display: flex;
    flex-direction: column;
    margin: 0;
    flex-shrink: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.ContentUnderline {
  border-bottom:  rgba(255, 255, 255, 0.1) 5px solid;
}

.Content > * > * {
    display: flex;
    flex-direction: column;
    padding: 0;
    text-align: left;
}

.ImageLink {
  cursor: pointer;
}

iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.iframeContainer {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 100%;
}

.ImageIcon {
  position: absolute;
  right: 10px;
  top: 10px; 
  font-size: 3em;
}

.TextColumnRight {
  margin-left: 0;
}

.TextColumnLeft {
  margin-right: 0;
}

.TextColumnRight > * {
  margin: 0 0 10px 0;
}

.TextColumnLeft > * {
  margin: 0 0 10px 0;
}

.positionRight {
  margin-left: 0;
}

.positionLeft {
  margin-right: 0;
}

.ColumnReverse {
  flex-direction: column-reverse;
}

.Description {
  margin-top: 10px;
}


@media all and (min-width: 800px) {
  .Content > * {
    flex-direction: row;
    flex: 1 0 0;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .Content > * > * {
    flex: 1 0 0;
    /* justify-content: center; */
  }

  .TextColumnRight {
    margin-left: 20px;
  }
  
  .TextColumnLeft {
    margin-right: 20px;
  }
  
  .positionRight {
    margin-left: 20px;
  }
  
  .positionLeft {
    margin-right: 20px;
  }
}
  
  
.FooterLinks > * {
    margin-left: 10px;
    text-decoration: none;
}

.FooterIcons > * {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background-color: var(--color3);
    cursor: pointer;
}

.FooterIcons > *:hover {
    background-color: var(--color5);
}

.Active {
    background-color: var(--color5);
    cursor: unset;
}

.default {
    mask-image: url('../../img/ao_icon.svg');
}

.Ordina {
    mask-image: url('../../img/ordina_icon.svg');
}

.CW {
    mask-image: url('../../img/cw_icon.svg');
}

@media all and (max-width: 400px) {
    .FooterIcons {
        display: none !important;
    }
}

.ThumbNailViewerHome a {
  /* display: flex !important;
  flex-flow: row wrap !important; */
  text-decoration: none;
}



.ThumbNailViewerFooter {
  justify-content: center;
  align-items: center;
  font-size: 0.5em;
}

.ThumbNail {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
}

.ThumbTxt {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: background-color 0.4s;
  transition: opacity 0.4s;
  color: var(--color6);
}

.ThumbNailRow {
  width: 50%;
  margin-bottom: 20px;
}

.ThumbTxt:hover {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.ThumbTxt > * {
  flex: 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}

.ThumbDescriptionNext {
  font-size: 2em;
}

.ThumbDescriptionHome {
  font-size: 5vw;
}

.ThumbTitle {
  font-size: 4vw;
}

.ThumbImg {
  display:flex;
}

.ThumbHomeImg {
  display: flex;
  flex: 1;
  width: 80%;
  margin: 20px 20px 0 20px;
  position: relative;
}

.ThumbHomeImgCarrousel {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.ThumbHomeImgCarrousel:nth-child(2) {animation-delay: 0.0s; }
.ThumbHomeImgCarrousel:nth-child(3) {animation-delay: 4.0s; }
.ThumbHomeImgCarrousel:nth-child(4) {animation-delay: 8.0s; }
.ThumbHomeImgCarrousel:nth-child(5) {animation-delay: 12.0s; }

.ThumbNailViewerHome a:hover .ThumbHomeImgCarrousel,
.ThumbNailViewerHome a:focus .ThumbHomeImgCarrousel {
  animation-name: carrousel;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.ImageIcon {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px; 
  font-size: 2em;
}

.ThumbHomeImg img{
  cursor: pointer;
}

.ThumbHomeTxt {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: initial;
  width: 80%;
  margin: 20px;
}

.ThumbHomeTxt > * {
  margin: 0;
}

.ThumbHomeRightCol {
  display: none;
  flex: 0;
  justify-content: center;
  font-size: 5em;
  color: var(--color2);
  /* opacity: 0; */
  transition: color 1s;
}

.ThumbNailViewerHome a:hover .ThumbHomeRightCol {
  opacity: 1;
  color: var(--color4);
}

.ThumbHomeImgPrivate {
  margin-bottom: 20px;
}

.Private {
  margin-top: 60px;
}

@media all and (min-width: 600px) {
  .ThumbDescriptionHome {
    font-size: 3vw;
  }
}

@media all and (min-width: 800px) {
  /* .ThumbNailViewerHome, */
  .ThumbNailViewerFooter {
    flex-flow: row wrap;
  }
  .ThumbNail {
    flex-direction: row;
  }
  .ThumbNailRow {
    flex: 1 0 26%;
    margin: 0 20px 0 0;
  }
  .ThumbDescription {
    font-size: 2em;
  }
 
  .ThumbDescriptionHome {
    font-size: 1.75vw;
  }
  
  .ThumbTitle {
    font-size: 2vw;
  }
      
  .ThumbLeft {
    margin-right: 20px;
  }

  .ThumbHomeTxt,
  .ThumbHomeImg {
    width: 100%;
    margin: 0;
  }

  .ThumbHomeTxt > * {
    margin: 20px;
  }
}

@media all and (min-width: 1200px) {
  .ThumbDescriptionHome {
    font-size: 1.25em;
  }  
  .ThumbHomeTxt {
    flex: 1;
  }
  
  .ThumbHomeRightCol {
    flex: 1;
    display:flex;
  }

  .ImageIcon {
    display: none;
  }  
}

@keyframes carrousel {
  0% {opacity: 0;}
  10% {opacity: 1;}
  30% {opacity: 1;}
  40% {opacity: 0;}
}
#Menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: -20px;
    background-color: var(--color2);
    top: 0;
    width: 70vw;
    height: 100vh;
    filter: drop-shadow(-4px 4px 4px var(--color7));
    font-size: 1.5em;
    line-height: 2em;;
    padding: 40px;
    text-align: left;
    transition: opacity 0.3s ease-in-out;
}

#Menu a {
    text-decoration: none !important;
}

#WideNav {
    display: none;
}

.Close {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 1.5em;
}

.ButtonLink:hover {
    color: var(--color5);
    cursor: pointer;
}

@media all and (min-width: 600px){
    #NarrowNav { 
        display: none;
    }

    #WideNav {
        display: flex;
    }

    #Menu {
        display: none;
    }
}
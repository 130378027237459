.imageLightbox {
    max-height: 100vh;
    max-width: 100vw;
    object-fit: contain;
}

.fullsizeImageLightbox {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    max-height: inherit;
    max-width: inherit;
    object-fit: none;
    overflow: scroll;
    cursor: zoom-out;
    vertical-align: top;
    box-sizing: border-box;
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    transition: opacity .4s;
}

.next, .prev {
    height: 100vh;
    opacity: 0;
    top: 0;
    width: 30vw;
    cursor: pointer;
    position: absolute;
    z-index: 1003;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

#lightboxClose {
    z-index: 1004;
    position: fixed;
    right: 10px;
    top: 10px;
    height: 40px;
    width: 40px;
    background-color:var(--color1);
    border-radius: 20px;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 0 1rem var(--color3));
}

.icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: var(--color2);
    mask-size: cover;
}

.icon:hover,
.icon:focus {
    background-color: var(--color3);
    opacity: 0.4;
}

.iconClose {
    mask-image: url('../../img/times.svg')
}

img {
    cursor: zoom-in;
}
  
.Content {
    text-align: left;
}

.Content h2 {
    text-align: left;
    margin-left: 0;
    margin-bottom: 0;
}

.Content > * {
    display: flex;
    flex-direction: column;
    margin: 0;
    flex-shrink: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.WorkExperience {
    flex-direction: row;
}


.WorkYears {
    flex: 2;
    margin: 0 0 20px 0;
    padding-bottom: 20px;
    color: var(--color4);
}

.WorkText {
    flex: 4;
    margin-top: 0 !important;
}

.WorkLogos {
    flex: 2;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-origin: content-box;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-position: center center;
    -webkit-mask-position: center center;
    mask-origin: content-box;
    -webkit-mask-origin: no-repeat;
    margin: 0 60px 20px 60px;
    padding-bottom: 20px;
}

.SkillColumns {
    display: flex;
    flex-direction: row;
}
.SkillColumns > * {
    flex: 1;
}

.EducationTitle {
    margin-bottom: 0;
}

.EducationBlocks {
    margin-top: 0 !important;
}

:global(.GlobalExpColor) {
    color: var(--color4);
}

:global(.GlobalExpBgColor) {
    background-color: var(--color4);
}

@media all and (max-width: 599px) {
    .SkillColumns {
        flex-direction: column;
    }
}

@media all and (max-width: 799px) {
    .WorkLogos {
        display: none;
    }
}
body {
  overflow-x: hidden;
  overflow-y: overlay;
  background-color: var(--color2);
}

body::-webkit-scrollbar,
.fullsize-image-lightbox::-webkit-scrollbar-thumb {
  width: 11px;
}
body,
.fullsize-image-lightbox {
  scrollbar-width: thin;
  scrollbar-color: var(--color1) var(--color2);
}
body::-webkit-scrollbar-track,
.fullsize-image-lightbox::-webkit-scrollbar-thumb {
  background: transparent;
}
body::-webkit-scrollbar-thumb,
.fullsize-image-lightbox::-webkit-scrollbar-thumb {
  background-color: var(--color1);
  background-clip: padding-box;
  border-style: solid;
  border-radius: 6px;
  border-width: 2px;
  border-color: transparent;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
  align-items: center;
  background-color: var(--color2);
  font-size: 1em;
  color: var(--color1);
  max-width: 1600px;
}

.App > * {
  display: flex;
  flex-direction: column;
  flex: 0;
  width: 94%;
  align-items: stretch;
  margin: 0;
  flex-shrink: 0;
}

a {
  color:var(--color1);
  text-decoration:underline;
}

a:hover {
  color: var(--color5);
}

img {
  width: 100%;
  height: auto;
}

h1, h2 {
  margin: 10px;
  text-align: center;
}

p {
  margin: 0 0 10px 0;
  padding: 0;
}

ul, 
ol {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
}

.OverlayText {
  font-size: 4vw;
}

.Header {
  border-bottom:  var(--color2) 1px solid;
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  color: var(--color1);
  position: relative;
}


.Header > * {
  flex: 1 1 49%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* height: 100%; */
}

.Header > * > h2 {
  color: transparent;
  margin: 0;
}

nav {
  align-self: flex-end;
  z-index: 10;
}

nav > * {
  flex: none;
  margin-left: 10px;
  display: flex;
}

nav > a{
  text-decoration:none;
}

button {
  appearance: auto;
  text-rendering: auto;
  color: var(--color1);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  margin: 0;
  border-width: 0px;
  background-color: transparent;
}

.Intro {
  flex-flow: row wrap;
  margin: 40px 0 60px 0;
  width: 75%;
}

.Intro > * {
  flex: 1 0 99%;   
}

.IntroText {
  flex: 8 0 51%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.IntroText p,
.IntroText h1 {
  margin-left: 0;
}

.IntroRightCol {
  flex: 0;
  display: flex;
  justify-content: center;
}

.IntroLeftCol {
  flex: 0;
}

.Footer {
  flex-direction: row-reverse;
  justify-content: space-between;
  flex: 0 40px;
  min-height: 40px;
  margin-top: 40px;
  padding: 10px 0 10px 0;
  border-top: var(--color4) 1px solid;
  color: var(--color1);
  /* font-size: 1.5em; */
}

.Footer > *{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-size: 0.6em; */
}

.LangBtn {
  cursor: pointer;
}
.LangBtn:hover {
  color: var(--color5);
  text-decoration: underline;
}

.ContentUnderline {
  border-bottom:  var(--color4) 1px solid;
  padding-bottom: 20px;
  margin: 20px 0 20px 0;
}

.ImageShadow {
  box-sizing: border-box;
  border: 1px solid var(--color3);
  align-self: flex-start;
  justify-self: flex-start;
}

.ImageHolder {
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

#logo {
  position: absolute;
  left: 0;
  min-width: 160px;
  min-height: 30px;
  background-color: var(--color5);
  mask-image: url('./img/ao_logo.svg');
  mask-repeat: no-repeat;
  mask-position: left 0px;
}

#companyLogo {
  align-self: flex-end;
  min-width: 160px;
  min-height: 30px;
}

.ImageHolder .svg-inline--fa,
.ImageIcon .svg-inline--fa {
  -webkit-filter: drop-shadow( 1px 1px 0 rgba(0, 0, 0, .3));
  filter: drop-shadow( 1px 1px 0 rgba(0, 0, 0, .3));
}

@media all and (max-width: 799px) {
  .Header {
    justify-content:space-between;
    align-items: center;
  }
}

@media all and (min-width: 600px) {
  .IntroRightCol {
    flex: 1 0 10%;
  }
  
  .IntroLeftCol {
    flex: 1 0 10%;
  }
  
}

@media all and (min-width: 800px) {
  .Intro {
    flex-direction: row;
    flex: 1 0 0;
    width: 94%;
    margin: 30px 0 80px 0;
    max-height: 300px;
  }

  .IntroText p,
  .IntroText h1 {
    margin-left: 20px !important;
  }

  .Intro > * {
    flex: 1 0 0;
    min-height: 300px;
    display:flex;
  }

  .IntroLeftCol {
    flex: 0;
  }

  .Photo {
    justify-content: center;
    align-items: center;
  }
  
  .IntroText {
    flex: 2;
  }

  .IntroRightCol {
    flex: 0;
    justify-content: center;
    align-items: center;
  } 

  .ImageHolder {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  #logo {
    min-width: 330px;
    min-height: 55px;
  }
}

@media all and (min-width: 1000px) {
  .App {
    width: 80%;
  }

  .Header {
    max-width: none;
  }

  .OverlayText {
    font-size: 2vw;
  }

  nav {
    padding-right: 0;
  }
  
}

@media all and (min-width: 1200px) {
  .App {
    font-size: 1em;
  }
  .IntroText {
    flex: 1 !important;
  }

  .IntroRightCol {
    flex: 1 !important;
  }
}

@media all and (min-width: 1440px) {
  .App {
    width: 1200px;
  }
}

.ImageHolder {
    position: relative;
    cursor: pointer;
}

.ImageHolder > * {
    display: flex;
}


.TextOverlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition: background-color 0.4s;
    transition: opacity 0.4s; 
    font-size: 2vw;
}

.TextOverlay:hover {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }

.TextOverlay > * {
    flex: 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5%;
}


  